<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div>
    <!-- <router-view></router-view> -->
    <NaviGation />
    <Foot />
  </div>
</template>

<script>
import NaviGation from '@/views/index/index'
import Foot from '@/views/foot/index'
export default {
  name: 'App',
  components: {
    NaviGation,
    Foot,
  },
}
</script>

<style>
@import '../src/assets/Global.css';
</style>

<template>
  <div class="main p">
    <div class="">
      <div class="pubTit">
        <h2><span>Exclusive </span> partnerships</h2>
      </div>
      <div class="imgs">
        <div class="scroll" style="--t: 50s" v-if="width > 768">
          <div class="div">
            <div
              v-for="(item, index) in data"
              :key="index"
              class="item"
              @mouseover="active1 = index"
              @mouseleave="active1 = -1"
            >
              <div v-if="active1 === index" class="abg">
                <div>@{{ item.name }}</div>
                <div>{{ item.fans }} subscribers</div>
                <a :href="urlFill(item.link)" target="_blank"
                  >More <img src="@a/images/honor/more@2x.png" alt=""
                /></a>
              </div>
              <img :src="item.imgUrl" alt="" v-else />
            </div>
          </div>
          <div class="div div2">
            <div
              v-for="(item, index) in data"
              :key="index"
              class="item"
              @mouseover="active2 = index"
              @mouseleave="active2 = -1"
            >
              <div v-if="active2 === index" class="abg">
                <div>@{{ item.name }}</div>
                <div>{{ item.fans }} subscribers</div>
                <a :href="urlFill(item.link)" target="_blank"
                  >More <img src="@a/images/honor/more@2x.png" alt=""
                /></a>
              </div>
              <img :src="item.imgUrl" alt="" v-else />
            </div>
          </div>
        </div>

        <div v-else>
          <VueSlickCarousel
            v-if="data.length"
            v-bind="settings"
            @afterChange="swipeCard"
            class="test"
          >
            <div
              v-for="(item, index) in data"
              :key="index"
              class="item1"
              :class="{ active: arrowIndex === index }"
            >
              <div v-if="arrowIndex === index" class="abg">
                <div>@{{ item.name }}</div>
                <div>{{ item.fans }} subscribers</div>
                <a :href="urlFill(item.link)" target="_blank"
                  >More <img src="@a/images/honor/more@2x.png" alt=""
                /></a>
              </div>
              <img :src="item.imgUrl" alt="" v-else />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { urlFill } from '@/utils'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import '@/assets/VueSlickCarousel.css'
import { partnerPage } from '@/api'
export default {
  name: 'PartnerC',
  props: {
    width: {
      type: Number,
    },
  },
  components: { VueSlickCarousel },
  data() {
    return {
      active1: -1,
      active2: -1,
      data: [],
      settings: {
        arrows: false,
        dots: false,
        fade: false, //淡化切换
        speed: 500, //动画时间
        slidesToShow: 2.3,
      },
      arrowIndex: 1,
      urlFill,
    }
  },
  created() {
    this.getPartnerList()
  },
  methods: {
    swipeCard(arrow) {
      if (arrow === this.data.length - 1) {
        this.arrowIndex = 0
        return
      }
      this.arrowIndex = arrow + 1
    },
    getPartnerList() {
      partnerPage()
        .then(({ data }) => {
          this.data = [...data]
        })
        .catch(error => error)
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.main {
  background-color: #110600;
  background-image: url('@a/images/honor/bg-5@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-family: AlibabaSans_Medium;

  .pubTit {
    padding-top: 100px;
    line-height: 44px;
    h2 {
      span {
        color: #fff;
      }
    }
  }
  .imgs {
    // width: 100%;
    padding: 80px 0 160px 0;
    ::v-deep .slick-list {
      overflow: unset !important;
    }
  }

  .scroll {
    display: flex;
    // overflow: hidden;
    /* mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent); */
    /* -webkit-mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent); */
    white-space: nowrap;
    margin: 0 auto;
    .div {
      white-space: nowrap;
      animation: animate var(--t) linear infinite;
      animation-delay: calc(var(--t) * -1);
      display: flex;
      align-items: center;
      .item {
        display: inline-block;
        letter-spacing: 1px;
        cursor: pointer;
        transition: background-color 0.5s;
        width: 30vh;
        height: 30vh;
        margin: 0 30px;
        &:hover {
          transform: translateY(-30px);
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
          overflow: hidden;
        }
      }
    }
    .div2 {
      animation: animate2 var(--t) linear infinite;
      animation-delay: calc(var(--t) / -2);
    }
    @keyframes animate {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
      }
    }

    @keyframes animate2 {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-200%);
      }
    }

    &:hover .div {
      animation-play-state: paused;
    }
    .abg {
      font-size: 20px;
      background-image: url('@a/images/honor/BB@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      white-space: normal;
      width: 100%;
      text-align: center;
      div:nth-child(2) {
        padding: 15px 0;
        font-size: 14px;
        font-family: AlibabaSans_Regular;
      }
      img {
        height: 15px !important;
        margin-left: 10px;
        object-fit: contain;
      }
      a {
        padding: 10px 20px;
        color: #fff;
        display: flex;
        align-items: center;
        border: 1px solid #ffead7;
        border-radius: 23px;
        font-size: 14px;
        font-family: AlibabaSans_Medium;
      }
    }
  }
  .item1 {
    display: inline-block;
    padding: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: background-color 0.5s;
    width: 15rem;
    height: 15rem;
    border-radius: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      // overflow: hidden;
    }
    &.active {
      transform: translateY(-20px) scale(1.05);
    }
    .abg {
      background-image: url('@a/images/honor/BB@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        text-align: center;
        width: 100%;
        font-size: 12px;
        white-space: normal;
      }
      div:nth-child(2) {
        padding: 0 0 10px 0;
        font-size: 14px;
        font-family: AlibabaSans_Regular;
        font-size: 12px;
      }
      img {
        height: 10px;
        object-fit: contain;
        margin-left: 5px;
      }
      a {
        padding: 3px 10px;
        color: #fff;
        display: flex;
        align-items: center;
        border: 1px solid #ffead7;
        border-radius: 23px;
        font-size: 12px;
        font-family: AlibabaSans_Medium;
      }
    }
  }

  @media (max-width: 768px) {
    .item {
      padding: 20px !important;
    }
    .imgs {
      padding: 50px 0;
    }
  }
  @media (max-width: 375px) {
    .item1 {
      width: 100px;
      height: 100px;
      max-width: 140px;
      max-height: 140px;
    }
  }
  @media screen and (max-width: 480px) and (min-width: 375px) {
    .item1 {
      width: 150px !important;
      height: 150px !important;
      max-width: 250px !important;
      max-height: 250px !important;
    }
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    .item1 {
      height: 200px !important;
      width: 200px !important;
      max-width: 250px;
      max-height: 250px;
    }
  }
}
</style>

<template>
  <section id="data">
    <div class="main2 p">
      <div class="pubCon">
        <div class="pubTit">
          <h2 v-if="width > 768">EMPOWERING CREATORS. MANAGING EVERYTHING.</h2>
          <div v-else>
            <h2>EMPOWERING</h2>
            <h2>CREATORS. MANAGING</h2>
            <h2>EVERYTHING.</h2>
          </div>
          <h3 v-if="width < 768">
            Transform your content, amplify results: our AI, data,creativity,
            and expertise deliver unmatched speed and success.
          </h3>
          <div v-else>
            <h3>Transform your content, amplify results: our AI, data,</h3>
            <h3>
              creativity, and expertise deliver unmatched speed and success.
            </h3>
          </div>
          <!-- <h3>creativity, and expertise deliver unmatched speed and success.</h3> -->
        </div>
        <div class="flex jc_se al_center list">
          <div v-for="(item, index) in imgList" :key="index" class="item">
            <img :src="item.url" alt="" />
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="dataList">
          <div
            class="dataItem"
            v-for="(item, index) in data"
            :key="index"
            :data-aos="width > 768 ? aosIndex(index) : ''"
          >
            <div>{{ item.data }}</div>
            <div class="tt">{{ item.target }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { dataPage } from '@/api'
import { aosIndex } from '@/utils'
export default {
  name: 'DataC',
  props: {},
  components: {},
  data() {
    return {
      imgList: [
        {
          text: 'PUBLISH',
          url: require('@/assets/images/about/publish@2x.png'),
        },
        { text: 'BOOST', url: require('@/assets/images/about/boost@2x.png') },
        {
          text: 'ANALYZE',
          url: require('@/assets/images/about/analyze@2x.png'),
        },
        {
          text: 'OPTIMIZE',
          url: require('@/assets/images/about/OPTIMIZE@2x.png'),
        },
        { text: 'TEST', url: require('@/assets/images/about/test@2x.png') },
      ],
      queryPage: {
        page: 1,
        pageSize: 6,
      },
      data: [],
      aosIndex,
      width: window.innerWidth,
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      dataPage({ ...this.queryPage })
        .then(({ data }) => {
          this.data = [...data]
        })
        .catch(error => error)
    },
    getWindowInfo() {
      this.width = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowInfo)
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.main2 {
  background-color: #110600;
  background-image: url('../../../assets/images/about/bg-2@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--text);

  h2 {
    font-size: 32px;
  }
  h3 {
    font-family: AlibabaSans_Regular;
  }
  .pubTit{
    padding-top: 80px !important;
  }
  .list {
    flex-wrap: wrap;
    .item {
      text-align: center;
      color: var(--color);
      padding: 20px;
      img {
        width: 10vh;
      }
      div {
        font-family: AlibabaSans_Medium;
      }
    }
  }
  .dataList {
    display: grid;
    // justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    padding: 18vh 0px;
    gap: 20px;

    .dataItem {
      border: 1px solid #ffead7;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 12vh 10px;
      cursor: pointer;
      .tt {
        font-family: AlibabaSans_Medium;
      }
      div {
        font-size: 20px;
      }
      div:nth-child(1) {
        font-size: 70px;
      }
      &:hover {
        color: var(--color);
        border: 1px solid var(--color);
      }
    }
  }
  @media (max-width: 768px) {
    .list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    h2 {
      font-size: 24px !important;
    }
    h3 {
      width: 100%;
    }
    .dataList {
      display: flex;
      flex-direction: column;
      padding: 10vh 0 3vh 0;
    }
    .dataItem {
      padding: 8vh 10px !important;
      div {
        font-size: 14px !important;
      }
      div:nth-child(1) {
        font-size: 50px !important;
      }
    }
  }
}
</style>

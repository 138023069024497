import axios from '@/plugins/axios'

// 获取全部数据
export function getfrontEndData() {
  return axios.get('/webMaintain/frontEndData')
}

// 发送邮箱
export function sendEmail(data) {
  return axios.post('/webMaintain/sendEmail', data)
}

// 数据维护列表
export function dataPage(params) {
  return axios.get('/webMaintain/dataPage', params)
}

// 荣誉维护列表
export function honorPage(params) {
  return axios.get('/webMaintain/honorPage', params)
}

// 新闻
export function newsPage(params) {
  return axios.get('/webMaintain/newsPage', params)
}

// 合作伙伴
export function partnerPage() {
  return axios.get('/webMaintain/partnerPage')
}

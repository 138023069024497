<template>
  <section id="ourServices" ref="ourServicesRef">
    <div class="ourServices p">
      <div class="pubCon">
        <div class="pubTit">
          <h2><span>Our </span>services</h2>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in servicesList"
            :key="index"
            @mouseover="active = index"
            @mouseleave="active = -1"
          >
            <img :src="item.imga" alt="" v-if="active === index" />
            <img :src="item.imgb" alt="" v-else />
            <div class="text">
              <div>{{ item.text1 }}</div>
              <div>{{ item.text2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesPage',
  props: {},
  components: {},
  data() {
    return {
      servicesList: [
        {
          text1: 'Copyright',
          text2: 'maintenance',
          imga: require('@a/images/ourServices/copyright-a@2x.png'),
          imgb: require('@a/images/ourServices/copyright-b@2x.png'),
        },
        {
          text1: 'Content',
          text2: 'optimization',
          imga: require('@a/images/ourServices/content-a@2x.png'),
          imgb: require('@a/images/ourServices/content-b@2x.png'),
        },
        {
          text1: 'Data',
          text2: 'analysis',
          imga: require('@a/images/ourServices/data-a@2x.png'),
          imgb: require('@a/images/ourServices/data-b@2x.png'),
        },
        {
          text1: 'Operation',
          text2: '& promotion',
          imga: require('@a/images/ourServices/Operation-a@2x.png'),
          imgb: require('@a/images/ourServices/Operation-b@2x.png'),
        },
        {
          text1: 'Revenue',
          text2: 'optimization',
          imga: require('@a/images/ourServices/revenue-a@2x.png'),
          imgb: require('@a/images/ourServices/revenue-b@2x.png'),
        },
        {
          text1: 'Policy',
          text2: 'synchronization',
          imga: require('@a/images/ourServices/policy-a@2x.png'),
          imgb: require('@a/images/ourServices/policy-b@2x.png'),
        },
        {
          text1: 'Massive',
          text2: 'copyright music',
          imga: require('@a/images/ourServices/massive-a@2x.png'),
          imgb: require('@a/images/ourServices/massive-b@2x.png'),
        },
        {
          text1: 'Value-added',
          text2: 'services',
          imga: require('@a/images/ourServices/Value-added-a@2x.png'),
          imgb: require('@a/images/ourServices/Value-added-b@2x.png'),
        },
      ],

      active: -1,
    }
  },

  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.ourServices {
  background-color: #110600;
  background-image: url('@a/images/ourServices/bg-3@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-family: AlibabaSans_Black;
  .pubCon {
    height: 100%;
  }
  .pubTit {
    h2 {
      span {
        color: #fff;
      }
    }
  }
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 160px;
    .item {
      background: rgba(255, 234, 215, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      padding: 20px;
      color: var(--color);
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: var(--color);
      }
      img {
        padding: 0px 0 150px 0;
        width: 5vh;
      }
      .text {
        padding: 20px 0;
        div {
          font-family: AlibabaSans_Medium;
        }
      }
    }
  }
  @media (max-width: 620px) {
  }
  @media (max-width: 1100px) {
    // .list {
    //   max-width: 1100px;
    // }
  }
  @media (max-width: 768px) {
    .list {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 50px;
      .item {
        img {
          padding: 0px 0 30px 0;
        }
      }
    }
  }
}
</style>

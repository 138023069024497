<template>
  <div class="foot">
    <div class="pubCon p">
      <h1 class="logo">
        <img src="@a/images/集团logo@2x.png" alt="" />
      </h1>
      <div class="flex jc_sb al_end bottom">
        <div class="left">
          <div class="flex al_center bop">
            <img src="@a/images/foot/电话@2x.png" alt="" />
            <div class="box">
              <div>Call us</div>
              <div class="text">+852 2803 0120</div>
            </div>
          </div>
          <div class="flex al_center bop">
            <img src="@a/images/foot/地址@2x.png" alt="" />
            <div class="box">
              <div>Address</div>
              <div class="text">
                Office Suite 1518,15/F，Shui On Centre,6-8 Harbour
                Road,Wanchai,Hong Kong.
              </div>
            </div>
          </div>
        </div>
        <div class="right flex">
          <div v-for="(item, index) in icons" :key="index">
            <a :href="item.url" target="_blank"
              ><img :src="item.img" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="tip">
      Made by Xiaowu Brothers (Hong Kong) Limited. All Right Reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootCon',
  props: {},
  components: {},
  data() {
    return {
      icons: [
        {
          img: require('@a/images/foot/facebook@2x.png'),
          url: 'https://www.facebook.com/xiaowubrother/',
        },
        {
          img: require('@a/images/foot/X@2x.png'),
          url: 'https://twitter.com/xiaowubrother',
        },
        { img: require('@a/images/foot/ins@2x.png') },
        {
          img: require('@a/images/foot/YouTube@2x.png'),
          url: 'https://www.youtube.com/@user-yl8dn9vk4e',
        },
        { img: require('@a/images/foot/tiktok@2x.png') },
      ],
    }
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.foot {
  background-color: #000;
  color: #fff;

  .logo {
    padding: 50px 0;
    img {
      width: 17vh;
    }
  }
  .bottom {
    padding-bottom: 50px;
  }
  .left {
    .bop {
      padding: 10px 0;
      img {
        margin-right: 20px;
        width: 4vh;
      }
      .box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          font-family: AlibabaSans_Medium;
          font-size: 14px;
        }
      }
      .text {
        font-family: AlibabaSans_Light !important;
        color: #ffead7;
      }
    }
  }
  .right {
    div {
      padding: 0 10px;
    }
    img {
      width: 4vh;
    }
  }
  .tip {
    text-align: center;
    border-top: 1px solid rgba(255, 234, 215, 0.2);
    font-family: AlibabaSans_Light;
    font-size: 12px;
    padding: 20px 0;
  }

  @media (max-width: 768px) {
    div {
      font-size: 12px !important;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      .right {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding-top: 50px;
        img {
          width: 6vh;
        }
      }
    }
  }
}
</style>

<template>
  <div class="box">
    <div
      class="list"
      :style="{ transform: `translateX(calc(${-scroll}% + ${gap}px))` }"
      style="transition: transform 1s ease"
      v-if="width > 960"
    >
      <div class="item mle" v-for="(item, index) in list" :key="index">
        <img src="@a/images/honor/奖杯.png" alt="" />
        <div>{{ item.honorName }}</div>
      </div>
    </div>

    <div v-else>
      <VueSlickCarousel v-bind="settings" class="list1" v-if="data.length">
        <div v-for="(item, index) in data" :key="index" class="list2">
          <div class="item2 mle" v-for="(ele, i) in item" :key="i">
            <img src="@a/images/honor/奖杯.png" alt="" />
            <div>{{ ele.honorName }}</div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <div class="icons flex jc_end" v-if="width > 960 && list.length > 4">
      <img src="@a/images/honor/向左.png" alt="" @click="scrollItems('left')" />
      <img
        src="@a/images/honor/向左.png"
        alt=""
        @click="scrollItems('right')"
      />
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import '@/assets/VueSlickCarousel.css'
import { honorPage } from '@/api'
export default {
  name: 'ScrollComponent',
  props: {
    width: {
      type: Number,
    },
  },
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      queryPage: {
        page: 1,
        pageSize: 999,
      },
      list: [],
      listData: ['123', '456'],
      scroll: 0,
      settings: {
        arrows: false, //左右切换按钮
        dots: true, //分页导航
        autoplay: false, //自动播放
        fade: false, //淡化切换
        speed: 500, //动画时间
        autoplaySpeed: 3000, //间隔时间
        slidesToShow: 1, // 同时显示的项目数量
        slidesToScroll: 1, // 每次滚动的项目数量
        // 间距配置
      },
      arr: [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
        [9, 10, 11, 12],
        [13, 14, 15, 16],
        [17, 18, 19, 20],
      ],
      arr1: [1, 2, 3, 4, 54, 5, 65, 6, 6, 67, 7, 7, 7, 5, 77, 7],
      data: [],
      gap: 0,
    }
  },

  created() {
    this.getList()
  },
  methods: {
    getList() {
      honorPage({ ...this.queryPage })
        .then(data => {
          this.list = [...data.data]
          this.data = this.handleData([...data.data])
        })
        .catch(error => error)
    },
    scrollItems(direction) {
      if (
        direction === 'right' &&
        this.scroll < (this.list.length / 2 - 2) * 50
      ) {
        this.scroll += 50
        this.gap -= 20
      }
      if (direction === 'left' && this.scroll > 0) {
        this.scroll -= 50
        this.gap += 20
      }
    },
    handleData(list) {
      let newList = []
      if (list.length) {
        var chunk = 4
        for (var i = 0, j = list.length; i < j; i += chunk) {
          newList.push(list.slice(i, i + chunk))
        }
      }
      return newList
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  // width: 80vw; /* 设置总体宽度为每列的宽度 */
  overflow: hidden;
  max-width: 1200px;
  padding-bottom: 20px;
  div {
    font-family: AlibabaSans_Medium;
  }
}

.list {
  display: flex;
  padding: 40px 0 0 0;
  flex-direction: column;
  flex-wrap: wrap;
  height: 300px;
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color);
    padding: 10px 10px 10px 0;
    color: #ffcfa2;
    width: 50%;
    height: 110px;
    margin-right: 20px;

    &:nth-child(odd) {
      border-top: 1px solid var(--color);
    }
    div {
      font-size: 20px;
      width: 90%;
    }

    img {
      margin-right: 10px;
      width: 3vh;
    }
  }
}
.list1 {
  padding: 20px 0 30px 0;
  .list2 {
    border-top: 1px solid var(--color);
    .item2 {
      display: flex;
      align-items: center;
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid var(--color);
      height: 120px;
      color: #ffcfa2;
      img {
        margin-right: 10px;
        width: 5vh;
      }
    }
  }
}
::v-deep .slick-dots {
  bottom: 0;
}
::v-deep .slick-dots li {
  margin: 0;
}
@media (max-width: 768px) {
}
@media screen and (max-width: 960px) {
}

.icons {
  padding: 20px 0;
  img {
    border: 1px solid var(--color);
    padding: 6px 10px;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 10px;
      transform: rotate(180deg);
    }
    &:hover {
      content: url('@a/images/honor/向右-点击.png');
      background-color: var(--color);
      &:nth-child(1) {
        transform: rotate(180deg);
      }
      &:nth-child(2) {
        transform: rotate(0deg);
      }
    }
  }
}
</style>

import Swal from 'sweetalert2'

export const toastSuccess = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'success',
    title: message,
  })
}
export const toastInfo = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'info',
    title: message,
  })
}
export const toastFail = message => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 2000,
  })

  return Toast.fire({
    icon: 'error',
    title: message,
  })
}
export const toastWait = (waitPromise, message) => {
  // Toast提示
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: false,
    timer: 1000,
  })

  Toast.fire({
    // title: message,
    icon: 'info',
    html: `<div>${message || '处理中，请稍等...'}</div>`,
  })

  Toast.stopTimer()

  return new Promise((resolve, reject) => {
    waitPromise
      .then(res => {
        Toast.resumeTimer()
        resolve(res)
      })
      .catch(err => {
        Toast.resumeTimer()
        reject(err)
      })
  })
}

export const modalSuccess = message => Swal.fire('成功!', message, 'success')

export const modalSuccessTitle = (title, message) =>
  Swal.fire(title, message, 'success')
export const modalFail = message =>
  Swal.fire('失败!', message || '未知错误，请联系管理员.', 'error')

export const modalErrorInfo = (message, html) =>
  Swal.fire({
    title: message,
    icon: 'error',
    html,
    confirmButtonColor: '#3085d6',
    confirmButtonText: '好的',
  })

export const modalEnquire = message =>
  Swal.fire({
    title: '请确认所填信息无误',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    reverseButtons: true,
    // cancelButtonColor: '#d33',
    confirmButtonText: '确认',
    cancelButtonText: '取消',
  })

export const modalEnquireTitle = (title, message) =>
  Swal.fire({
    title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消',
  })

export const modalEnquireBtn = (
  title,
  text,
  confirmButtonText,
  denyButtonText,
  cancelButtonText
) =>
  Swal.fire({
    title,
    text,
    showDenyButton: !!denyButtonText,
    showCancelButton: !!cancelButtonText,
    confirmButtonText,
    denyButtonText,
    cancelButtonText,
    width: 600,
    padding: '3em',
    color: '#716add',
    background: '#fff url("https://sweetalert2.github.io/images/trees.png")',
    backdrop: `
    rgba(0,0,123,0.4)
    url("https://sweetalert2.github.io/images/nyan-cat.gif")
    left top
    no-repeat
  `,
  })

// waitFunc 需要等待时得是一个返回Promise的函数，立即执行的就无所谓
export const modalEnquireWait = (
  waitFunc,
  title,
  text,
  noWait = false,
  funcArgs = []
) =>
  Swal.fire({
    title: title || '请确认所填信息无误',
    text,
    icon: 'warning',
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    showLoaderOnConfirm: true,
    preConfirm: () =>
      new Promise((resolve, reject) => {
        try {
          resolve(waitFunc.call(null, ...funcArgs))
        } catch (e) {
          reject(e)
        }
      }),
    allowOutsideClick: () => noWait || !Swal.isLoading(),
    backdrop: () => noWait || !Swal.isLoading(),
  })

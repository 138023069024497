<template>
  <section id="honor" ref="honorRef">
    <div class="honorPage p">
      <div class="pubCon">
        <div class="pubTit"><h2>Honor</h2></div>
        <div>
          <v-parallax :src="banner" v-if="width > 1100"></v-parallax>
          <img :src="banner" alt="" v-else />
        </div>

        <Scroll :data="dataList" :width="width" />
      </div>
    </div>
    <Partner :partnerList="list.length && list" :width="width" />
    <!-- <hy /> -->
  </section>
</template>

<script>
// import hy from './components/hy.vue'
import Scroll from './components/scroll.vue'
import Partner from './components/partner.vue'
export default {
  name: 'HonorC',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { Partner, Scroll },
  data() {
    return {
      banner: require('@a/images/honor/banner@2x.png'),
      // dataList: [
      //   {
      //     list: [
      //       { text: '1  Honored as YouTubes MCN Partner of the Year in 2020.' },
      //       {
      //         text: '2  Won the YouTube Shorts Award, and the monthly data of Shorts project ranked the second in the world in 2022.',
      //       },
      //     ],
      //   },
      //   {
      //     list: [
      //       { text: '3  Honored as YouTubes MCN Partner of the Year in 2020.' },
      //       {
      //         text: '4  Won the YouTube Shorts Award, and the monthly data of Shorts project ranked the second in the world in 2022.',
      //       },
      //     ],
      //   },
      // ],
      dataList: [
        { text: '1  Honored as YouTubes MCN Partner of the Year in 2020.' },
        {
          text: '2  Won the YouTube Shorts Award, and the monthly data of Shorts project ranked the second in the world in 2022.',
        },
        { text: '3  Honored as YouTubes MCN Partner of the Year in 2020.' },
        {
          text: '4  Won the YouTube Shorts Award, and the monthly data of Shorts project ranked the second in the world in 2022.',
        },
        { text: '5  Honored as YouTubes MCN Partner of the Year in 2020.' },
        {
          text: '6  Won the YouTube Shorts Award, and the monthly data of Shorts project ranked the second in the world in 2022.',
        },
      ],
      width: window.innerWidth,
    }
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowInfo)
  },
}
</script>

<style scoped lang="scss">
.honorPage {
  background-color: #110600;
  background-image: url('@a/images/honor/bg-4@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: AlibabaSans_Black;
  .pubTit {
    // padding: 80px 0 30px 0;
    h2 {
      span {
        color: #fff;
      }
    }
  }
  img {
    width: 100%;
  }

  .v-parallax__content {
    width: 100%;
  }
  @media (max-width: 768px) {
    .v-parallax {
      width: 100%;
    }
  }
}
</style>

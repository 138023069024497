import { render, staticRenderFns } from "./partner.vue?vue&type=template&id=77af1451&scoped=true"
import script from "./partner.vue?vue&type=script&lang=js"
export * from "./partner.vue?vue&type=script&lang=js"
import style0 from "./partner.vue?vue&type=style&index=0&id=77af1451&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77af1451",
  null
  
)

export default component.exports
<template>
  <section id="news" ref="newsRef">
    <div class="newsPage p">
      <div class="pubCon">
        <div class="pubTit"><h2>News</h2></div>
        <div class="flex" style="padding: 0 0 5vh 0">
          <div class="left">
            <img src="@a/images/news/left@2x.png" alt="" />
          </div>
          <div class="right">
            <div>
              <div class="line" v-if="width > 768"></div>
              <div class="list">
                <div class="item" v-for="(item, index) in data" :key="index">
                  <div class="title">
                    <img :src="item.iconImgUrl" alt="" />
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="content">" {{ item.summary }} "</div>
                  <div class="flex">
                    <div class="btn">
                      <a :href="urlFill(item.link)" target="_blank"
                        >Read More</a
                      >
                      <img src="@a/images/news/B@2x.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex jc_end end"
              v-if="width >= 768 && data.length >= 3"
            >
              <img src="@a/images/news/T.png" alt="" @click="prev" />
              <img src="@a/images/news/Bot.png" alt="" @click="next" />
            </div>
            <div
              v-if="width < 768 && data.length >= 3"
              class="load"
              @click="loading"
            >
              <span> More</span> <img src="@a/images/news/加载.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { urlFill } from '@/utils'
import { newsPage } from '@/api'
export default {
  name: 'NewsPage',
  props: {},
  components: {},
  data() {
    return {
      data: [],
      queryPage: {
        page: 1,
        pageSize: 3,
      },
      total: 0,
      width: window.innerWidth,
      urlFill,
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      newsPage({ ...this.queryPage })
        .then(({ data, total }) => {
          this.data = [...data]
          this.total = total
        })
        .catch(error => error)
    },
    prev() {
      if (this.queryPage.page <= 1) return
      this.queryPage.page--
      this.getNewsList()
    },
    next() {
      if (this.total / this.queryPage.pageSize <= this.queryPage.page) return
      this.queryPage.page++
      this.getNewsList()
    },
    loading() {
      if (this.total / this.queryPage.pageSize <= this.queryPage.page) return
      this.queryPage.page++
      newsPage({ ...this.queryPage })
        .then(({ data }) => {
          this.data.push(...data)
        })
        .catch(error => error)
    },
    getWindowInfo() {
      this.width = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowInfo)
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.newsPage {
  background-color: #110600;
  background-image: url('@a/images/news/bg-6@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;

  .pubTit {
    h2 {
      span {
        color: #fff;
      }
    }
    padding-bottom: 20px;
  }
  .left {
    width: 45%;
    text-align: center;
    img {
      width: 10vw;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .line {
      border-top: 2px solid var(--color);
      width: 100%;
    }
    .list {
      .item {
        padding: 30px 0;
        .title {
          display: flex;
          align-items: center;
          span {
            font-family: AlibabaSans_Regular;
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            margin-right: 10px;
          }
        }
        .content {
          font-family: AlibabaSans_BlackItalicAlt;
          padding: 20px 0;
          color: #ffcfa2;
          font-size: 18px;
        }
        .btn {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          border: 1px solid var(--color);
          border-radius: 10px;
          cursor: pointer;
          a {
            color: var(--color);
            font-family: AlibabaSans_Medium;
          }
          img {
            width: 5px;
            margin-left: 10px;
            object-fit: contain;
          }
          &:hover {
            background-color: var(--color);
          }
          &:hover a {
            color: #fff;
          }
          &:hover img {
            content: url('@a/images/news/A@2x.png');
          }
        }
      }
    }
    .end {
      img {
        padding: 10px 6px;
        border: 1px solid var(--color);
        cursor: pointer;

        &:hover {
          content: url('@a/images/news/hoverTop@2x.png');
          background-color: var(--color);
        }
        &:nth-child(2) {
          margin-left: 10px;
          &:hover {
            content: url('@a/images/news/hoverBom@2x.png');
            background-color: var(--color);
          }
        }
      }
    }
    .load {
      color: var(--color);
      margin: 0 auto;
      cursor: pointer;

      img {
        // padding: 0 5px;
        // width: 30px;
        vertical-align: middle;
      }
    }
  }

  @media (max-width: 768px) {
    .left {
      display: none;
    }
    .right {
      .item {
        .btn {
          padding: 6px !important;
          a {
            font-size: 14px;
          }
          img {
            width: 5px !important;
          }
        }
      }
    }
  }
}
</style>

<template>
  <section id="contactUs" ref="contactUsRef">
    <div class="contactUs p">
      <div class="pubCon h">
        <div class="pubTit">
          <h2><span>Contact </span>us</h2>
        </div>
        <div class="type">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in typeList"
              :key="index"
              :class="{ active: active === index }"
              @click="choic(item.text, index)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <section id="form">
          <v-form class="form flex" ref="form">
            <div class="left w">
              <v-row>
                <v-col> Your Name </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    outlined
                    class="bop"
                    v-model="formData.contacts"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row><v-col> Your Email </v-col> </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    outlined
                    class="bop"
                    v-model="formData.email"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="right w">
              <v-row> <v-col> Message </v-col> </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    class="bop"
                    v-model="formData.message"
                    :rules="[required]"
                    height="215px"
                  ></v-textarea
                ></v-col>
              </v-row>
            </div>
          </v-form>
          <v-btn @click="sendEmail">Send</v-btn>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { required, asynchronousReturn } from '@/utils'
import { toastSuccess, toastInfo } from '@/utils/prompt.js'
import { sendEmail } from '@/api'
export default {
  name: 'ContactPage',
  props: {},
  components: {},
  data() {
    return {
      required,
      typeList: [
        { text: 'Creator', type: '' },
        { text: 'Talent rep（Agent/Manager）', type: '' },
        { text: 'Brand', type: '' },
        { text: 'Media company', type: '' },
        { text: 'Social media partner', type: '' },
      ],
      active: 0,
      errorActive: false,
      formData: {
        contacts: '',
        email: '',
        message: '',
        customerType: 'Creator',
      },
    }
  },
  methods: {
    sendEmail() {
      asynchronousReturn(this.$refs.form.validate)
        .then(() => {
          sendEmail({ ...this.formData })
            .then(data => {
              this.errorActive = false
              if (data.status === 200) toastSuccess('Sent successfully!')
              this.reset()
            })
            .catch(error => error)
        })
        .catch(error => {
          this.errorActive = true
          toastInfo('Please provide complete information')
          return error
        })
    },
    choic(type, index) {
      this.active = index
      this.formData.customerType = type
      this.scrollTo('#form')
    },
    reset() {
      this.$refs.form.reset()
    },
    scrollTo: function (targetId) {
      var targetElement = document.querySelector(targetId)

      // 使用 scrollIntoView 方法实现平滑滚动
      targetElement.scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.contactUs {
  background-color: #110600;
  background-image: url('@a/images/contact/bg-7@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: AlibabaSans_Black;
  .pubTit {
    h2 {
      span {
        color: #fff;
      }
    }
  }
  .h {
    padding: 30px 0 80px 0;
  }
  .type {
    border-bottom: 1px solid rgba(255, 234, 215, 0.2);
    width: 100%;
    padding-bottom: 80px;
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .item {
        text-align: center;
        padding: 15px 0;
        width: 30%;
        border: 1px solid #ffead7;
        border-radius: 8px;
        font-family: AlibabaSans_Medium;
        cursor: pointer;

        &:hover {
          border: 1px solid var(--color);
          background-color: var(--color);
        }
        &.active {
          border: 1px solid var(--color);
          background-color: var(--color);
        }
      }
    }
  }
  .form {
    padding-top: 80px;
    font-family: AlibabaSans_Regular !important;
    .w {
      width: 50%;
      ::v-deep .v-text-field--outlined fieldset {
        border: 1px solid rgba(255, 234, 215, 0.5);
        // color: #ffead7;
        border-radius: 8px;
      }
      .col {
        font-family: AlibabaSans_Regular !important;
      }
      &.active {
        ::v-deep .v-text-field--outlined fieldset {
          border: 1px solid red !important;
        }
      }
      ::v-deep .theme--light.v-input input {
        color: #ffead7 !important;
        font-family: AlibabaSans_Regular !important;
      }
      ::v-deep .theme--light.v-messages {
        color: red;
      }
      ::v-deep .v-messages__message {
        font-family: AlibabaSans_Regular !important;
      }
      ::v-deep .theme--light.v-input textarea {
        font-family: AlibabaSans_Regular !important;
      }
    }

    .left {
    }
    .right {
      flex: 1;
      ::v-deep .theme--light.v-input textarea {
        color: #ffead7 !important;
      }
    }
  }
  .v-btn {
    background-color: var(--color) !important;
    color: #fff;
    text-transform: none;
    padding: 25px 30px !important;
  }
  ::v-deep .v-btn__content {
    letter-spacing: 0px;
  }
  ::v-deep .v-btn__content {
    font-family: AlibabaSans_Medium !important;
  }

  @media (max-width: 768px) {
    .list {
      display: flex;
      flex-direction: column;
      .item {
        width: 100% !important;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      padding-top: 80px;
      .w {
        width: 100% !important;
        .col {
          padding: 8px 12px;
        }
        .row + .row {
          margin-top: 0;
        }
      }
    }
    .v-btn {
      margin-top: 10px;
      padding: 10px 15px !important;
    }

    .right {
      margin-top: 10px;
    }
  }
}
</style>

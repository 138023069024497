import axios from 'axios'
import { modalFail } from '@/utils/prompt'

console.log(process.env);

const serviceUrlPro = 'https://www.xiaowubros.com/api'
// const serviceUrlDev = 'http://172.16.25.106:8091'
const serviceUrlDev = 'https://www.xiaowubros.com/api'

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
}

// let version = '02'

const axiosConfig = {
  baseURL: envServiceUrlMap[process.env.NODE_ENV],
}

export class RequestHttp {
  service

  constructor(configs) {
    this.service = axios.create(configs)

    this.service.interceptors.request.use(
      conf => {
        return conf
      },
      error => Promise.reject(error)
    )

    this.service.interceptors.response.use(
      response => {
        // 下载问题修复
        if (
          response.data &&
          response.data.status &&
          response.data.status !== 200
        ) {
          modalFail(response.data.message || '未知错误！')

          return Promise.reject(response.data.message)
        }

        return response.data
      },
      error => {
        /* 删除"系统内部异常"字样 */
        // eslint-disable-next-line no-param-reassign,no-unused-expressions
        error.response.data.message?.includes('系统内部异常：') &&
          (error.response.data.message = error.response.data.message.slice(7))

        return Promise.reject(error)
      }
    )
  }

  post(url, params, _config = {}) {
    return this.service.post(url, params, _config)
  }

  get(url, params = {}, _config = {}) {
    return this.service.get(url, { params, ..._config }, { ..._config })
  }

  put(url, params, _config = {}) {
    return this.service.put(url, params, _config)
  }
}

export default new RequestHttp(axiosConfig)

import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Vuetify from 'vuetify'
import vuetify from '@/plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

Vue.use(Vuetify)

Vue.use(AOS.init())
new Vue({
  vuetify,
  AOS,
  render: h => h(App),
}).$mount('#app')

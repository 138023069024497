<template>
  <div class="header" :class="{ active: isSticky }">
    <div class="navBar" :class="{ open: isOpenMenu }">
      <div class="nav_logo">
        <img src="@/assets/images/logo@2x.png" alt="" />
      </div>
      <div class="nav_menu">
        <div
          v-for="(item, index) in navList"
          :key="index"
          @click.prevent="scrollTo('#' + item.id, index)"
          :class="{ active: activeNav === index }"
        >
          <a :data-nav="`#${item.id}`">{{ item.itmeName }}</a>
        </div>
        <div class="lang">
          <div class="line">|</div>
          <a href="http://www.xiaowubros.cn" target="_blank"
            ><img src="@a/images/navbar/lang-1.png" alt="" />CN</a
          >
        </div>
      </div>
      <div class="burger" @click="fn">
        <v-icon v-if="!isOpenMenu" size="32">mdi-menu</v-icon>
        <v-icon v-else size="32">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {},
  data() {
    return {
      navList: [
        { ids: '001', id: 'aboutUs', itmeName: 'About us' },
        { ids: '002', id: 'ourServices', itmeName: 'Our services' },
        { ids: '003', id: 'honor', itmeName: 'Honor' },
        { ids: '004', id: 'news', itmeName: 'News' },
        { ids: '005', id: 'contactUs', itmeName: 'Contact us' },
      ],
      isOpenMenu: false, //小屏幕下是否显示菜单
      activeNav: 0,
      isSticky: false,
      scrollTop: 0,
    }
  },
  methods: {
    scrollTo: function (targetId, index) {
      var targetElement = document.querySelector(targetId)

      // 使用 scrollIntoView 方法实现平滑滚动
      targetElement.scrollIntoView({
        behavior: 'smooth',
      })
      this.isOpenMenu = !this.isOpenMenu
      this.activeNav = index
    },
    handleScroll() {
      this.isSticky = window.scrollY > 10
      this.isOpenMenu = false
    },
    fn() {
      this.isOpenMenu = !this.isOpenMenu

      if (this.isOpenMenu) {
        this.isSticky = true
      }
    },
    checkPosition() {
      this.scrollTop = document.documentElement
        ? document.documentElement.scrollTop
        : document.body.scrollTop

      const titles = document.querySelectorAll('[data-nav]')

      for (let i = 0; i < titles.length; i++) {
        const title = titles[i]
        const targetSelector = title.getAttribute('data-nav')
        const targetOffsetTop = document.querySelector(targetSelector).offsetTop

        if (this.scrollTop >= targetOffsetTop) {
          this.activeNav = i
        } else {
          // title.classList.remove('active')
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', () => {
      this.checkPosition()
    })
  },
  watch: {},
  computed: {},
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 80px;
  // background-color: rgba(102, 102, 102, 0.1);
  position: fixed;
  top: 0;
  z-index: 99;
  &.active {
    background-color: #040404;
  }
}

.navBar {
  max-width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0 20px;
  color: #ffffff;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 200;
  top: 0;
  left: 0;
}
.nav_logo {
  img {
    max-width: 16vh;
  }
}
.nav_menu {
  display: flex;
  align-items: center;
  // height: 100%;
  .lang {
    div {
      margin: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      margin-right: 10px;
      margin-top: 3px;
      width: 15px;
      object-fit: contain;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: AlibabaSans_Regular;
    cursor: pointer;
    &:hover a {
      color: var(--color);
    }
    &:hover img {
      content: url('@a/images/navbar/lang-2.png');
    }
    &.active {
      a {
        color: var(--color);
      }
      &:nth-child(5) {
        border: 1px solid var(--color);
      }
    }

    &:nth-child(5) {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--text);
      padding: 4px 16px;
      border-radius: 18px;
      &:hover {
        border: 1px solid var(--color);
      }
      a {
        margin: 0;
      }
    }
  }

  a {
    color: #ffead7;
    text-decoration: none;
    margin: 0 20px;
    font-family: AlibabaSans_Regular;
  }
}

.burger {
  display: none;
}

@media screen and (max-width: 960px) {
  .lang{
    .line{
      display: none !important;
    }
  }
  .nav_menu {
    display: none;
  }
  .burger {
    display: block;
    cursor: pointer;
    z-index: 20;
  }
  .burger .v-icon {
    color: #ffffff;
  }

  .nav_logo {
    max-width: 13vh;
  }
  .navBar.open .nav_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    position: absolute;
    right: 0;
    top: 80px;
    width: 100%;
    background-color: #040404;
    /* opacity: 0; */
    -webkit-animation: slideDown 0.5s ease-out 0s 1 normal forwards;
    animation: slideDown 0.5s ease-out 0s 1 normal forwards;
  }
  .navBar.open .nav_menu a {
    margin: 0;
    padding: 16px 20px;
  }
  .navBar.open .nav_menu div {
    border: none;
  }

  .navBar.open .nav_logo {
    color: #aaaaaa;
    z-index: 20;
  }
  .navBar.open .burger div {
    background-color: #aaaaaa;
  }
  /* 导航列表 */
  @-webkit-keyframes slideDown {
    from {
      top: -55%;
      opacity: 0;
    }
    to {
      top: 79px;
      opacity: 1;
    }
  }
  @keyframes slideDown {
    from {
      top: -55%;
      opacity: 0;
    }
    to {
      top: 79px;
      opacity: 1;
    }
  }
}
</style>

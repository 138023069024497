<template>
  <section id="aboutUs" ref="aboutUsRef">
    <div class="aboutPage">
      <NavBar />
      <div class="pubCon main p">
        <div class="tit">
          <img src="@a/images/about/XIAOWU BROS.大标题@2x.png" alt="" />
        </div>
        <div class="text">
          <h2>ABOUT US</h2>
          <h3>
            <span>XIAOWU BROS.</span>
            empowering creators to thrive, unleashing the full potential of
            their communities, and maximizing earnings across global social
            media platforms.
          </h3>
        </div>
        <div class="mouse">
          <img src="@a/images/about/滑动.png" alt="" v-if="width > 620" />
          <a v-else @click.prevent="scrollTo('#data')"
            ><img src="@a/images/about/向下@2x.png" alt=""
          /></a>
        </div>
      </div>
    </div>
    <Data />
  </section>
</template>

<script>
import Data from './components/data.vue'
import NavBar from '@v/components/navbar/index'
export default {
  name: 'HomePage',
  props: {},
  components: {
    NavBar,
    Data,
  },
  data() {
    return {
      width: window.innerWidth,
    }
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth
    },
    scrollTo: function (targetId) {
      var targetElement = document.querySelector(targetId)

      // 使用 scrollIntoView 方法实现平滑滚动
      targetElement.scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowInfo)
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.aboutPage {
  background-image: url('../../assets/images/about/aboutbg@2x@2x-2-2-2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background-color: #110600;

  .main {
    .tit {
      padding-top: 23vh;
      max-width: 55vh;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin-top: 7vh;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      h2 {
        font-size: 50px;
        font-family: AlibabaSans_Black;
        font-weight: 900;
        color: #ffead7;
        letter-spacing: 1px;
      }
      h3 {
        font-weight: normal;
        letter-spacing: 1px;
        color: #ffead7;
        width: 500px;
        font-family: AlibabaSans_Regular;
        span {
          color: var(--color);
          font-family: AlibabaSans_Regular;
        }
      }
    }
    @media (max-width: 620px) {
      .tit {
        max-width: 40vh;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        h2 {
          font-size: 32px;
        }
        h3 {
          margin-top: 5vh;
          width: 100%;
        }
      }
    }

    .mouse {
      text-align: center;
      margin-top: 20vh;
      a {
        img {
          width: 40px;
        }
      }
    }
  }
}
</style>

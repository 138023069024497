<template>
  <div class="index">
    <!-- 首页 -->
    <AboutUs />
    <!-- 我们的服务 -->
    <OurServices />
    <!-- 荣誉 -->
    <Honor />
    <!-- 新闻 -->
    <News />
    <!-- 联系我们 -->
    <ContactUs />
  </div>
</template>

<script>
import Honor from '@v/Honor/index'
import AboutUs from '@v/AboutUs/index'
import News from '@v/News/index'
import ContactUs from '@v/ContactUs/index'
import OurServices from '@v/OurServices/index'
export default {
  name: 'NaviGation',
  props: {},
  components: {
    AboutUs,
    News,
    ContactUs,
    OurServices,
    Honor,
  },
  data() {
    return {}
  },

  created() {},
  methods: {
  },
  mounted() {
   
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss"></style>

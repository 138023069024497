// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify, {
  VBtn,
  VParallax,
  VForm,
  VRow,
  VCol,
  VTextField,
  VTextarea,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  icons: {
    iconfont: 'mdi',
  },
  components: {
    VBtn,
    VParallax,
    VForm,
    VRow,
    VCol,
    VTextField,
    VTextarea,
  },
  directives: {
    Ripple,
  },
})

export default new Vuetify()
